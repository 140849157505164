@import "https://code.highcharts.com/css/highcharts.css";

.perf-chart .highcharts-color-0 {
  fill: green;
  stroke: green;
}

.perf-chart .highcharts-root {
  font-family: "Gill Sans, Poppins";
}
