@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/Gill\ Sans.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Bold';
  src: url('./assets/fonts/Gill\ Sans\ Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans Italic';
  src: url('./assets/fonts/Gill\ Sans\ Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


body {
  margin: 0;
  padding: 0;
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a {
  text-decoration: none;
}

b {
  font-family: "Gill Sans Bold", "Poppins", sans-serif !important;
}

h1 {
  font-family: "Gill Sans Bold", "Poppins", sans-serif !important;
}

h2 {
  font-family: "Gill Sans Bold", "Poppins", sans-serif !important;
}

h3 {
  font-family: "Gill Sans Bold", "Poppins", sans-serif !important;
}


input {
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  font-size: 14px;
}


html {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.default-bg {
  background-color: white !important;
}

.inverted-bg {
  background-image: url("../src/contents/img/rampver-bg-lines.png");
  background-color: #256141 !important;
}

.signup-paper {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}

.paper {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}

.signup-form {
  width: "100%";
}

.signup-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
}

.ISA-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.ISA-question {
  font-size: 30px;
  font-weight: 600;
  line-height: 48px;
}

.ISA-item-container {
  margin: auto;
  width: 60%;
  padding: 10px;
}

.MuiContainer-root {
  padding: 0 !important;
}

.choice-root {
  font-family: "Gill Sans","Poppins", sans-serif !important;
  background: rgba(37, 97, 65, 0.1) !important;
  padding: 12px !important;
  border: 2px solid #bec0bf !important;
  /* box-sizing: border-box !important; */
  border-radius: 5px !important;
  margin: 10px 0px 20px 0px !important;
  width: 500px;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-family: "Gill Sans","Poppins", sans-serif !important;
}

.notice-label {
  margin-left: 10px;
  font-family: "Gill Sans", "Poppins";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1pt;
}

.link-cancel {
  font-family: "Gill Sans", "Poppins";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1pt;
  color: #000 !important;
  text-decoration: none;
}

.margin-top-60px {
  margin-top: 60px;
}

@media only screen and (max-width: 720px) {
  .ISA-item-container {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 1340px) {
  .MuiContainer-root {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 1340px) {
  #signin-container {
    margin-top: 0px !important;
  }

  .signup-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 48px;
    
  }

}

@media only screen and (max-width: 450px) {
  .signup-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 48px;
  }

}

@media only screen and (max-width: 400px) {
  .signup-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
  }

}

#signin-container {
  margin-top: 140px;
}

.sub-title {
  font-family: "Gill Sans", "Poppins", san-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

.sub-title a {
  color: #c8932a;
}

.dark-label-short {
  font-size: 14px;
  font-weight: 1000;
}
.dark-label {
  font-size: 14px;
  font-weight: 400;
}

#message {
  margin: auto;
  width: 75%;
}

.r-title-container {
  padding-top: 5%;
}

.r-title {
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}

.text-light {
  color: #fff;
}

.text-light-1 {
  color: #87b39b;
}

@media only screen and (max-width: 720px) {
  #message {
    margin: auto;
    width: 100%;
    height: 100%;
  }

  .r-title {
    font-size: 28px !important;
    font-weight: 600;
    margin: 0;
  }
  .overlay-alert .alert-box {
    width: auto !important;
  }
}

.text-center {
  text-align: center !important;
}

.r-subtitle {
  font-size: 20px;
  font-weight: normal;
  margin: 20px;
}

.sign-with {
  color: #9f9f9f;
  font-size: 14px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-even {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pull-right {
  display: flex;
  justify-content: left;
  align-items: center;
}

.pull-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.flex .border {
  background: #9f9f9f;
  width: 70%;
  height: 1px;
}

.btn-google {
  display: inline;
  border: 1px solid #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: "Gill Sans", "Poppins", sans-serif;
  color: #9f9f9f;
  cursor: pointer;
}

.btn-google img {
  height: 20px;
  margin: -3px 10px -3px 0px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-30px {
  margin-top: 30px;
}

.latest-news {
  background: white;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  overflow: hidden;
}

.latest-news img {
  height: 140px;
}

.latest-news .text {
  padding: 20px;
  height: 140px;
  font-size: 12px;
}

._title {
  color: #0d242c;
  font-family: "Gill Sans", "Poppins", sans-serif;
  margin: 0;
}

.drawer-container {
  margin: 10px 50px;
}

.drawer-title {
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
}

.drawer-subtitle {
  font-size: 14px;
  font-weight: normal;
}

.drawer-icon-container {
  text-align: center;
  padding: 15px;
  background-color: #256141;
  border-radius: 50%;
  color: #256141;
  width: 56px !important;
  height: 56px !important;
  margin: 20px 0 20px 0;
}

.drawer-icon {
  color: #fff;
  margin: auto;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.btn-back {
  font-family: "Gill Sans", "Poppins";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1pt;
  color: #000 !important;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
}

.not-verified {
  padding: 15px;
  display: flex;
  align-items: center;
}

.not-verified a {
  color: white;
  text-decoration: none;
}

.not-verified span {
  margin-left: 10px;
}

.dashboard-bar-mobile {
  width: 100%;
}

.dashboard-bar-box-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.dashboard-bar-box-mobile b {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.dashboard-bar-box-mobile span {
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  padding: 1px;
}

.dashboard-bar {
  padding: 40px;
  width: 100%;
}

.dashboard-bar-steps {
  padding: 15px;
  width: 100%;
}

.dashboard-bar-flex {
  display: flex;
}

.dashboard-bar-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
  justify-content: center;
}

.dashboard-bar-box b {
  font-size: 40px;
  font-weight: 800;
  text-align: center;
}

.dashboard-bar-box span {
  font-size: larger;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}

.body-content {
  padding: 20px;
}

._title {
  color: #0d242c;
}

.total-earning {
  color: #c8932a;
  display: flex;
}

.as-of {
  color: #444444;
}

.totals {
  margin-top: 20px;
}

.totals .total {
  display: flex;
  height: 50px;
}

.totals .total .icon {
  color: white;
  text-align: center;
  width: 50px;
  padding: 10px 10px;
  border-radius: 100%;
}

.totals .invested .icon {
  background: #256141;
}

.totals .depreciation .icon {
  background: #c8932a;
}

.totals .risk .icon {
  background: #ed5555;
}

.totals .total .icon svg {
  position: relative;
  top: 2px;
}

.totals .total .amount {
  margin-left: 10px;
}

.totals .total .amount .title {
  color: #444444;
  font-size: 14px;
}

.totals .total .amount .number {
  color: #0d242c;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.d-flex {
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
}

.d-flex span {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0px;
}

.my-investments {
  margin-top: 40px;
}

.investment-container {
  margin-top: 30px;
  cursor: pointer;
}

.investment-container table {
  width: 100%;
}

.investment-container table td {
  font-size: 13px;
  font-weight: 600;
  padding: 5px;
  border-bottom: 1px solid #dadada;
}

.investment-container:hover {
  background-color: #f8f8f8;
}

.investment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
  padding: 5px 0px;
}

.investment-box b {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.03em;
}

.investment-box span {
  font-weight: 400;
  font-style: italic;
}

.investment-display {
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
}

.rampver-color {
  color: #256141;
}

.rampver-bgColor {
  background: #256141 !important;
  color: white !important;
}

.rampver-bgColorDisabled {
  background: #d9d9d9 !important;
  color: black !important;
}

.rampver-errColor {
  background: #ed5555;
  color: white;
}

._title {
  color: #0d242c;
  font-family: "Gill Sans", "Poppins", sans-serif;
  margin: 0;
}

.valid-id {
  color: #256141;
}

.upload-title,
.valid-id {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.process-completed > span {
  font-weight: 600 !important;
  
  margin: 0 !important;
}
.sidebar-text:hover {
  border-bottom: 3px solid gray !important;
}



.process-current > span {
  color: #c8932a !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.list-item-default > span {
  color: white;
  margin-left: 0px !important;
  list-style: none !important;
}

.pie {
  margin-bottom: 30px;
}

.net-asset {
  padding: 20px;
  border-radius: 5px;
}

.content-margin {
  /* background: red; */
  padding: 20px;
  margin-top: 30px;
}

.net-asset .title {
  display: flex;
}

.net-asset .title svg {
  position: relative;
  top: 10px;
}

.net-asset table {
  width: 100%;
}

.net-asset table thead th {
  font-size: 20px;
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #dadada;
}

.net-asset table td {
  border-bottom: 1px solid #dadada;
  padding: 10px;
}

.suggested-funds .title {
  position: relative;
  width: 100%;
}

.suggested-box {
  margin-bottom: 30px;
  margin-right: 30px;
  width: 379px;
}

.suggested-box .indicator {
  color: white;
  padding: 5px;
  border-radius: 10px;
  font-size: 11px;
}

.suggested-box .indicator.moderate {
  background: #256141;
}

.suggested-box .indicator.conservative {
  background: #507be9;
}

.suggested-box .indicator.aggressive {
  background: #ed5555;
}

.suggested-box table {
  width: 100%;
}

.suggested-box table td {
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
}

.text-moderate {
  color: #256141;
}

.text-conservative {
  color: #507be9;
}

.text-aggressive {
  color: #ed5555;
}

.text-gold {
  color: #c8932a;
}

.text-ytd-above {
  color: #4CAF50
}

.text-ytd-below {
  color: #F44336
}

.suggested-box .min {
  margin: 10px 0px;
}

.suggested-box .min span {
  padding-bottom: 10px;
}

.help button {
  background: #256141;
  color: white;
  position: absolute;
  right: 50px;
  top: 0;
}

.help button:hover {
  background: #256141;
}

.copyright {
  margin: auto;
  text-align: center;
  position: relative;
  top: 20px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.modal-paper {
  color: #000;
  background-color: #fff;
  border: none;
  padding: 25px;
  width: 70%;
  border-radius: 15px;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.modal-icon {
  height: 50px !important;
  width: 50px !important;
  color: #256141;
}

.modal-icon-error {
  height: 50px !important;
  width: 50px !important;
  color: rgb(165, 28, 28);
}

.select-input > div {
  padding: 12px !important;
  font-size: 14px !important;
  font-family: "Gill Sans", "Poppins", sans-serif;
}

.assessment-select-input > div {
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  font-size: 20px !important;
}

._searchbar {
  min-width: 400px !important;
}

.all-categories {
  margin-top: 20px;
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  align-items: center; */
  /* justify-content: left; */
}

.all-categories .category {
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 166px;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.all-categories .category {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}

.icon-category {
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.content-category .icon-category {
  background: white;
  margin: auto;
}

.all-categories .category .icon-category svg {
  color: #256141;
}

.flexbox {
  display: flex;
  flex-wrap: wrap;
}

.rampver-package-box {
  border: 1px solid #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  width: 349px;
  height: 279px;
  padding: 20px;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.rampver-package-box h3 span {
  color: rgb(26, 176, 113);
}

.rampver-package-box .percentage {
  color: #256141;
}

a {
  cursor: pointer;
}

.inv-by-category .title .icon-category {
  display: table-cell;
  text-align: center;
}

.inv-by-category .title {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.inv-by-category .title .title-name {
  margin-left: 20px;
}

.inv-by-category .title .title-name h1 {
  margin: 0;
}

.custom-tabs .MuiButtonBase-root {
  padding: 20px;
}

.custom-tabs .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: none;
  font-weight: bold;
}

.funds .fund-upper .custom-btn {
  color: white !important;
  text-transform: none;
  font-weight: bold;
  float: right;
  margin-top: 10px;
  padding: 10px 40px;
}

.fund-upper .fund-title .amount h1 {
  margin: 0px 0px 20px;
}

.fund-upper .fund-title .amount .custom-chip {
  font-family: "Gill Sans", "Poppins", sans-serif;
  margin-left: 10px;
  font-size: 12px;
  color: #269b5d;
  background: rgba(36, 96, 64, 0.1);
  border: none;
  font-weight: bold;
}

.fund-upper .fund-title .amount {
  display: flex;
  align-items: center;
  padding: 0;
}

.fund-upper .fund-title .amount2 {
  display: flex;
  align-items: left;
}

.fund-preview .subscription h2 {
  margin: 0px;
}

.fund-preview .subscription {
  padding: 20px;
  border: 2px solid #256141;
  box-sizing: border-box;
  border-radius: 10px;
  width: 381px;
}

.fund-preview .subscription table {
  width: 100%;
}

.fund-preview .subscription table td {
  padding: 10px 0px;
  text-align: left;
}

.fund-preview .subscription table th {
  padding: 10px 0px;
  text-align: left;
}

.tbl-odd-even {
  width: 100%;
  border-collapse: collapse;
}

.tbl-odd-even th {
  text-align: left;
}

.tbl-odd-even td {
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #dadada;
}

.tbl-odd-even tbody tr:nth-child(even) {
  background: white;
}

.tbl-odd-even tbody tr:nth-child(odd) {
  background: #f2f2f2;
}

.tbl-border-bottom {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  table-layout: fixed;
}

.tbl-border-bottom tbody tr td:first-child {
  font-weight: bold;
  width: 40%;
}

.tbl-border-bottom tbody tr td:last-child {
  font-weight: 400;
}

.tbl-border-bottom tbody td {
  font-size: 14px;
  padding: 10px 15px;
  border-bottom: 1px solid #dadada;
}

.graphs {
  padding: 0px 50px;
}

.input-php {
  width: 100%;
  padding: 10px;
}

.input-php p {
  margin-top: -20px;
  font-weight: bold;
}

.exceeding {
  font-size: 12px;
  display: flex;
  margin-top: 5px;
  align-items: center;
  color: #c8932a;
}

.upload .custom-btn {
  font-weight: bold;
  color: white;
  text-transform: none;
  padding: 10px 40px;
}

.upload .title {
  display: flex;
  align-items: center;
}

.upload .title h3 {
  margin-right: 10px;
}

.upload-box {
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  height: 100%;
  position: relative;
  width: 100%;
  text-align: center;
}

.upload-file {
  display: auto;
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-box span {
  position: relative;
  bottom: 60px;
}

.custom-list-upload {
  padding: 10px 0px;
  box-shadow: none !important;
}

.custom-chip-upload {
  background: none !important;
  font-weight: bold;
  color: #256141 !important;
}

.quick-note h2 {
  margin: 0 0 10px 0;
}

.quick-note {
  background: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  padding: 50px;
}

.quick-note span b {
  color: rgb(26, 176, 113);
}

.redemption-modal {
  padding: 0px 40px 75px;
}

.redemption-second {
  padding: 10px;
}

.redemption-second table {
  width: 100%;
}

.redemption-second table td {
  padding: 10px 0px;
  text-align: left;
}

.redemption-second table th {
  padding: 10px 0px;
  text-align: left;
  width: 85%;
}

.break-down {
  background: #f8f8f8;
  border-radius: 10px;
  width: 40%;
  padding: 10px;
}

.overlay-alert {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 36, 44, 0.95);
  left: 0;
}

.overlay-alert .alert-box {
  margin: auto;
  width: 444px;
  position: relative;
  top: 200px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.alert-box .custom-btn {
  font-weight: bold;
  text-transform: none;
  color: white;
}

.icon-bgRampver {
  color: white;
  background: #256141;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.custom-nav-links li a span {
  margin-left: 30px;
  color: rgb(255, 255, 255);
}

.custom-nav-links li a.active {
  color: #e7e7e7 !important;
}

.custom-nav-links li a {
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
}

.amount-range {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.amount-range input[type="number"] {
  width: 100px;
  padding: 5px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
}

.tbl-container {
  padding-left: 40px;
}

.tbl-primary-list {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tbl-primary-list thead {
  background: #256141;
  color: white;
}

.tbl-primary-list thead th {
  padding: 20px;
}

.tbl-primary-list tbody td {
  padding: 20px;
}

.tbl-primary-list tbody tr:nth-child(even) {
  background: white;
}

.tbl-primary-list tbody tr:nth-child(odd) {
  background: rgba(37, 97, 65, 0.1);
}

.history-trans-id .title span b {
  color: #256141;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  width: 65%;
}

.buttons-container button {
  color: white;
  text-transform: none;
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  padding: 10px 40px;
  margin-right: 20px;
}

.buttons-container label {
  color: #256141;
}

.buttons-container label svg {
  position: relative;
  top: 5px;
}

.custom-date-range {
  width: 20%;
  padding-left: 50%;
}

.MuiPickersDatePickerRoot-toolbar h6 {
  color: white;
}

.MuiPickersDatePickerRoot-toolbar h4 {
  color: white;
}

.MuiPickersCalendar-transitionContainer .MuiPickersDay-daySelected p {
  color: white !important;
}

.align-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.chip-fund {
  background-color: #256141 !important;
  color: #fff !important;
}

.up-rate {
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 12px !important;
  color: #000 !important;
  background: rgb(202, 250, 184) !important;
  border: none;
  font-weight: bold;
}

.up-rate > span::before {
  content: "+ ";
}

.same-rate {
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 12px !important;
  color: #000 !important;
  background: rgb(223, 240, 128) !important;
  border: none;
  font-weight: bold;
}

.same-rate > span::before {
  content: "";
}

.down-rate {
  font-family: "Gill Sans", "Poppins", sans-serif !important;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 12px !important;
  color: #000 !important;
  background: rgb(245, 179, 160) !important;
  border: none;
  font-weight: bold;
}

.down-rate > span::before {
  content: "";
}

.bank-account-container {
  padding: 20px;
  border: 2px solid #256141;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  margin: 0 !important;
}

.payment-bank {
  border-radius: 5px;
  width: 100%;
  margin: 20px auto 30px auto;
}

.card-text {
  font-size: 15px !important;
}

.investment-box-none {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.investment-box-none-mobile {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.my-investments .title {
  display: flex;
  justify-content: space-between;
}

.my-investments .title h2 {
  margin: 0;
}

.my-investments .title h2 span {
  font-size: 13px;
  font-weight: normal;
}

.my-investments .title h4 {
  margin: 0;
  margin-top: 20px;
  cursor: pointer;
}

.suggested-funds .suggested-list {
  display: flex;
  flex-wrap: wrap;
}

.all-companies {
  margin-top: 20px;
  width: 100%;
}

.all-companies .companies {
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 166px;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.all-companies .companies {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}

.responsive {
  width: 100%;
  height: 100%;
}

.notif-menu-item-main {
  width: 400px !important;
  background: rgb(255, 255, 255) !important;
  padding: 0 !important;
}

.w-max {
  width: 100%;
}

#scrollableDiv {
  height: 600px !important;
  overflow: auto !important;
}

.infiscroll-menu-item {
  /* height: 100px !important; */
  margin: 6px !important;
  padding: 8px !important;
  flex-grow: 1 !important;
}

.infiscroll-menu-item:active {
  background-color: black;
}

#scrollableDiv::-webkit-scrollbar {
  width: 7px !important;
  /* width of the entire scrollbar */
}

#scrollableDiv::-webkit-scrollbar-track {
  background: #c4c3c2 !important;
  /* color of the tracking area */
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background-color: #256141 !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
}

.notif-skeleton {
  padding: 6px !important;
}

.MuiPaper-root > ul {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.notif-header {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px !important;
}

.notif-footer {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px !important;
  cursor: pointer;
}

.infiscroll-menu-item-active {
  background: #e5f4ee !important;
  border-radius: 5px !important;
}

.infiscroll-menu-item-inactive {
  background: #d9dbdb !important;
  border-radius: 5px !important;
}

.notif-acc-circle {
  height: 40px !important;
  width: 40px !important;
}

.notif-item-msg {
  font-family: "Gill Sans", "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.notif-item-date {
  color: #868686;
  font-family: "Gill Sans", "Poppins", sans-serif;
  font-size: 11px;
  font-weight: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.custom-tabs-vertical {
  width: 100%;
}

.dark-label.bold {
  font-weight: bold;
}

.my-application {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.my-application tbody tr td:first-child {
  font-weight: bold;
  color: #a4a4a4;
}

.my-application .header {
  font-weight: bold;
  color: black !important;
  font-size: 18px;
}

.my-application tbody td {
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid #dadada;
}

.email-handler-img {
  height: auto;
  width: 200px;
}

.risk-app {
  border: 1px solid #dadada;
  padding: 15px;
  display: flex;
  align-items: center;
  font-weight: normal !important;
  font-size: 14px;
}

.risk-app span {
  margin-left: 20px;
}

.risk-app span h4 {
  margin: 0;
}

.risk-app .iconSize {
  padding: 15px;
  border-radius: 100%;
}

.risk-app.moderate .iconSize {
  background: #256141;
}

.risk-app.conservative .iconSize {
  background: #507be9;
}

.risk-app.aggressive .iconSize {
  background: #ed5555;
}

.custom-autocomplete .MuiAutocomplete-input {
  padding: 5px !important;
}

.mt10px {
  margin-top: 10px;
}

.mt20px {
  margin-top: 20px;
}

.mt30px {
  margin-top: 30px;
}

.mt50px {
  margin-top: 50px;
}

.mb30px {
  margin-bottom: 30px;
}

.documents {
  margin-top: 15px;
  width: 100%;
}

.documents td {
  vertical-align: center;
  cursor: pointer;
}

.d-none {
  display: none;
}

.data-privacy {
  font-size: 14px;
}

.data-privacy h2 {
  color: #256141;
}

.data-privacy-link {
  color: #ffffff;
  text-decoration: underline;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
  min-height: 150px;
}

.payment-details-1-ol {
  counter-reset: item;
}

.payment-details-1-ol li {
  display: block;
  margin-bottom: 5px;
}

.payment-details-1-ol li::before {
  display: inline-block;
  content: "Step " counter(item) ":";
  counter-increment: item;
  width: 4em;
  font-weight: bold;
}

.payment-details-1-ol ul li::before {
  content: " ";
  counter-reset: item;
}

.payment-details-2-ol {
  counter-reset: item 3;
}

.payment-details-2-ol li {
  display: block;
  margin-bottom: 5px;
}

.payment-details-2-ol li::before {
  display: inline-block;
  content: "Step " counter(item) ":";
  counter-increment: item;
  width: 4em;
  font-weight: bold;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.youtube-playlist-grid {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
}

.youtube-playlist-grid img {
  max-width: 100%;
}

.youtube-playlist-card {
  width: 67%;
  border: 1px solid #c8932a;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.youtube-playlist-card:hover,
.youtube-playlist-card:focus,
.youtube-playlist-card:active {
  color: #c8932a;
  border-color: #c8932a8a;
}

.centered-flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-number-input:focus {
  border-color: #c8932a !important;
  box-shadow: 0 0 0 1px #c8932a !important;
}
