@tailwind utilities;

body {
  margin: 0;
  font-family: "Gill Sans", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  height: 100% !important;
}

#root,
html {
  height: 100%;
}
